import React from "react";
import "./Landingpage.scss"
class LaningPage extends React.Component {
  render() {
    return <>
       <div className="home">
      <div className="div">
        <p className="text-wrapper">Chào mừng bạn đến với Soul Reader</p>

        <div className="text-wrapper-2">Tarot Reader</div>

        <div className="text-wrapper-3">Tính năng nổi bậc</div>

        <div className="text-wrapper-4">Blog</div>

        <p className="p">
          Trang web chuyên kết nối bạn với các chuyên gia Tarot uy tín, cung cấp
          dịch vụ giải mã trực tuyến. Tại đây, bạn có thể nhận được sự hướng dẫn
          và phân tích sâu sắc từ các Tarot reader, thông qua cả hình thức trực
          tuyến và kết nối trực tiếp. Với Soul Reader, bạn dễ dàng khám phá
          những thông điệp tiềm ẩn, tìm ra câu trả lời cho các vấn đề trong cuộc
          sống, tình cảm và sự nghiệp, từ những chuyên gia giàu kinh nghiệm
          trong lĩnh vực Tarot.
        </p>

        <p className="text-wrapper-5">
          Giúp bạn kết nối trực tuyến với các chuyên gia Tarot, cho phép bạn
          chọn một reader phù hợp và đặt lịch hẹn dễ dàng. Trong buổi tư vấn,
          bạn sẽ nhận được những phân tích sâu sắc về tình yêu, sự nghiệp và các
          khía cạnh khác trong cuộc sống. Các reader sẽ cung cấp những thông
          điệp cụ thể, giúp bạn có cái nhìn rõ ràng hơn về tương lai và hỗ trợ
          bạn trong việc giải quyết thắc mắc. Đây là cơ hội tuyệt vời để bạn tìm
          kiếm sự thấu hiểu và bình an trong tâm hồn.
        </p>

        <p className="text-wrapper-6">
          Chào mừng bạn đến với blog Tarot chuyên chia sẻ về các trải bài. Tại
          đây, bạn sẽ tìm thấy nhiều hướng dẫn và phân tích chi tiết, giúp giải
          đáp thắc mắc và khám phá bản thân thông qua từng trải bài.
        </p>

        <p className="text-wrapper-7">Tổng quan về Bộ bài Tarot</p>

        <p className="text-wrapper-8">Tổng quan về Bộ bài Tarot</p>

        <div className="text-wrapper-9">24/10/2024</div>

        <div className="text-wrapper-10">24/10/2024</div>

        <div className="text-wrapper-11">Liên hệ</div>

        <div className="text-wrapper-12">Thông tin chung</div>

        <div className="text-wrapper-13">Hướng dẫn sử dụng</div>

        <div className="overlap">
          <div className="text-wrapper-14">Chính sách bảo mật</div>

          <div className="text-wrapper-15">Điều khoản sử dụng</div>
        </div>

        <div className="text-wrapper-16">Miễn trừ trách nhiệm</div>

        <div className="text-wrapper-17">SĐT: 000-000-0000</div>

        <div className="text-wrapper-18">Email: abc@gmail.com</div>

        <img
          className="line"
          alt="Line"
          src="https://c.animaapp.com/1SOvTHRb/img/line-9.png"
        />

        <div className="element-soulreader"> © 2024 SoulReader</div>

        <div className="overlap-group">
          <div className="ellipse" />

          <img
            className="image"
            alt="Image"
            src="https://c.animaapp.com/1SOvTHRb/img/image@2x.png"
          />

          <img
            className="img"
            alt="Image"
            src="https://c.animaapp.com/1SOvTHRb/img/image-1@2x.png"
          />

          <img
            className="image-2"
            alt="Image"
            src="https://c.animaapp.com/1SOvTHRb/img/image-2@2x.png"
          />
        </div>

        <img
          className="image-3"
          alt="Image"
          src="https://c.animaapp.com/1SOvTHRb/img/image-3.png"
        />

        <div className="overlap-2">
          <div className="rectangle" />

          <div className="rectangle-2" />

          <div className="rectangle-3" />

          <div className="rectangle-4" />

          <div className="text-wrapper-19">Bói bài online</div>

          <p className="text-wrapper-20">Trải bài với chuyên gia</p>

          <div className="text-wrapper-21">Bói bài Tarot</div>

          <div className="text-wrapper-22">Trải bài tarot 1:1</div>

          <p className="text-wrapper-23">
            Khám phá những bí ẩn của cuộc sống. Với những phương pháp bói bài
            Tarot độc đáo, bạn sẽ nhận được những lời khuyên quý báu giúp bạn
            định hình con đường phía trước.
          </p>

          <p className="text-wrapper-24">
            Nhận những lời khuyên riêng từ các chuyên gia để hiểu rõ hơn về bản
            thân và cuộc sống. Mỗi lá bài sẽ giúp bạn tìm ra những câu trả lời
            cho những vấn đề quan trọng mà bạn đang đối mặt.
          </p>

          <img
            className="image-4"
            alt="Image"
            src="https://c.animaapp.com/1SOvTHRb/img/image-4@2x.png"
          />

          <img
            className="image-5"
            alt="Image"
            src="https://c.animaapp.com/1SOvTHRb/img/image-4-1@2x.png"
          />
        </div>

        <div className="overlap-3">
          <div className="rectangle-5" />

          <div className="text-wrapper-25">XEM NGAY</div>
        </div>

        <div className="overlap-4">
          <div className="rectangle-5" />

          <div className="text-wrapper-26">XEM THÊM</div>
        </div>

        <div className="overlap-5">
          <div className="rectangle-6" />

          <div className="text-wrapper-27">TRẢI BÀI ONLINE</div>
        </div>

        <div className="overlap-6">
          <div className="rectangle-7" />

          <div className="text-wrapper-28">ĐĂNG KÝ MIỄN PHÍ</div>
        </div>

        <div className="overlap-7">
          <div className="rectangle-8" />

          <div className="group">
            <p className="text-wrapper-29">Bước 1: Chọn gói dịch vụ</p>

            <p className="text-wrapper-30">
              Bắt đầu bằng cách lựa chọn gói dịch vụ phù hợp với nhu cầu của
              mình về thời gian và loại hình tư vấn.
            </p>
          </div>

          <div className="rectangle-9" />
        </div>

        <div className="overlap-8">
          <div className="rectangle-10" />

          <div className="group-2">
            <div className="text-wrapper-29">Bước 3: Thanh toán</div>

            <p className="text-wrapper-31">
              Sau khi xác nhận lịch hẹn, bạn thực hiện thanh toán để hoàn tất
              quy trình đặt lịch.
            </p>
          </div>

          <div className="rectangle-9" />
        </div>

        <div className="overlap-9">
          <div className="rectangle-8" />

          <div className="group-3">
            <p className="text-wrapper-29">Bước 2: Đặt lịch hẹn</p>

            <p className="text-wrapper-32">
              Tiến hành đặt lịch hẹn với Tarot reader mà bạn muốn kết nối. Hệ
              thống sẽ hiển thị các thời gian có sẵn để bạn dễ dàng lựa chọn.
            </p>
          </div>

          <div className="rectangle-9" />
        </div>

        <div className="overlap-10">
          <div className="rectangle-10" />

          <div className="group-4">
            <p className="text-wrapper-29">Bước 4: Đăng ký thành công</p>

            <p className="text-wrapper-33">
              Cuối cùng, bạn sẽ nhận được thông báo xác nhận đăng ký thành công
              cùng thông tin chi tiết về buổi tư vấn sắp tới.
            </p>
          </div>

          <div className="rectangle-9" />
        </div>

        <p className="text-wrapper-34">
          Bộ bài Tarot kết nối tiềm thức, giúp bạn khám phá bản thân .....
        </p>

        <p className="text-wrapper-35">
          Bộ bài Tarot kết nối tiềm thức, giúp bạn khám phá bản thân .....
        </p>

        <p className="text-wrapper-36">
          Làm thế nào để kết nối 1:1 với Tarot Reader
        </p>

        <img
          className="image-6"
          alt="Image"
          src="https://c.animaapp.com/1SOvTHRb/img/image-3-1.png"
        />

        <div className="navbar">
          <div className="text-wrapper-37">Trang chủ</div>

          <div className="text-wrapper-38">Tarot Reader</div>

          <div className="text-wrapper-39">Blog</div>

          <div className="text-wrapper-40">Lịch hẹn</div>

          <div className="text-wrapper-41">Trải bài online</div>
        </div>

        <div className="group-5">
          <div className="overlap-11">
            <div className="rectangle-11" />

            <div className="text-wrapper-42">Đăng ký</div>
          </div>

          <div className="div-wrapper">
            <div className="text-wrapper-43">Đăng nhập</div>
          </div>
        </div>

        <img
          className="rectangle-12"
          alt="Rectangle"
          src="https://c.animaapp.com/1SOvTHRb/img/rectangle-47@2x.png"
        />

        <img
          className="rectangle-13"
          alt="Rectangle"
          src="https://c.animaapp.com/1SOvTHRb/img/rectangle-47@2x.png"
        />

        <div className="overlap-12">
          <div className="rectangle-14" />

          <div className="text-wrapper-44">XEM BÀI VIẾT</div>
        </div>

        <div className="overlap-13">
          <div className="rectangle-14" />

          <div className="text-wrapper-44">XEM BÀI VIẾT</div>
        </div>

        <img
          className="group-6"
          alt="Group"
          src="https://c.animaapp.com/1SOvTHRb/img/group-47486@2x.png"
        />

        <img
          className="group-7"
          alt="Group"
          src="https://c.animaapp.com/1SOvTHRb/img/group-47496@2x.png"
        />

        <div className="group-8">
          <img
            className="vector"
            alt="Vector"
            src="https://c.animaapp.com/1SOvTHRb/img/vector@2x.png"
          />

          <img
            className="group-9"
            alt="Group"
            src="https://c.animaapp.com/1SOvTHRb/img/group-47495@2x.png"
          />
        </div>
      </div>
    </div> 
    </>;
  }
}
export default LaningPage;
